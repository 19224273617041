@import './media-queries';
@import './colors';

@mixin fontLogoTitle {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.7rem;
  letter-spacing: 0;
  color: $color-white;
  margin: 0;
}

@mixin fontH1 {
  font-family: 'Dancing Script', sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  color: $color-white;
  margin: 0;
  height: 6rem;
  display: flex;
  align-items: center;

  @include mq-desktop {
    height: 10.2rem;
  }

  span {
    font-size: 5rem;
    line-height: 5rem;

    @include mq-desktop {
      font-size: 8.5rem;
    }
  }

  span:first-child {
    color: $color-primary;
  }
}

@mixin fontH2 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.5rem;
  text-align: center;
  color: $color-text;
  margin: 0;
}

@mixin fontSecondary {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;
  letter-spacing: 0;
  color: $color-white-opacity-70;

  @include mq-desktop {
    line-height: 2.2rem;
    font-size: 1.4rem;
  }
}

@mixin fontButtonPrimary {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0;
  color: $color-white;

  @include mq-desktop {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

@mixin fontButtonSecondary {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: $color-white;
}

@mixin fontBody {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: $color-text;
  margin: 0;
}

@mixin fontSmall {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: $color-text-light;
  margin: 0;
}

@mixin fontPercentage {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.6rem;
  letter-spacing: 0;
  text-align: center;
  color: $color-secondary;
  margin: 0;

  @include mq-desktop {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}

@mixin fontPercentageSmall {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.1rem;
  text-align: center;
  color: $color-secondary;
  margin: 0;

  @include mq-desktop {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
