@import '../../styles/common';

.logoSection {
  padding-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @include mq-desktop{
    opacity: 0;
  }
}

.logoImg {
  width: 18rem;

}

.logoTitle {
  @include fontLogoTitle;
}

.mainSection {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mq-desktop {
    transform: translate(-50%, -50%);
  }
}

.mainTitle {
  @include fontH1;

  text-align: center;
}

.mainDescription {
  @include fontSecondary;

  margin-top: 1.2rem;
  margin-bottom: 2.4rem;
  text-align: center;
  max-width: 27.2rem;
  
  @include mq-desktop {
    max-width: 33.8rem;
    margin-top: 0.8rem;
    margin-bottom: 3.2rem;
  }
}

.startButton {
  @include fontButtonPrimary;
  -webkit-tap-highlight-color: transparent;
  width: 21.1rem;
  padding: 1.2rem 0 1.3rem;
  display: inline-block;
  border: 0;
  border-radius: 3.3rem;
  text-align: center;
  line-height: inherit;
  background: $color-primary-button;
  text-decoration: none;

  &:active {
    background: $color-primary-pressed;
  }

  &:hover {
    background: $color-primary-hover;
  }

  @include mq-desktop {
    width: 26rem;
    padding: 1.2rem 0 1.3rem;
  }
}

.awardSection {
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  @include mq-desktop {
    bottom: calc(2vh * 3);
  }
}

.awardImg {
  width: 29.6rem;

  @include mq-desktop {
    width: 30.8rem;
  }
}