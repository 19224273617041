@import '../../styles/common';

.footer {
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: $color-footer;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  @include mq-desktop {
    flex-direction: row;
    p {
      margin-right: 3rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}