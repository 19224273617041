$color-white: #FFFFFF;
$color-white-opacity-70: #FFFFFFB2;
$color-primary: #57D9BA;
$color-primary-button: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #4CC6A8;
$color-primary-pressed: #44B499;
$color-primary-hover: #51D8B7;
$color-secondary: #8F1CB8;
$color-secondary-opacity-5: rgba(143, 28, 184, 0.05);
$color-secondary-opacity-10: rgba(143, 28, 184, 0.1);
$color-secondary-opacity-40: rgba(143, 28, 184, 0.4);
$color-secondary-hover: #9C24C7;
$color-secondary-pressed: #7F19A3;
$color-text-light: #AAB2BD;
$color-text-light-opacity-40: #D8DBE0;
$color-text-light-opacity-10: #F7F7F8;
$color-text: #282A2F;
$color-text-opacity-20: #EBEDEF;
$color-error: #F8587E;
$color-highlight: #F7D838;
$color-highlight-border: #F6D838;
$color-footer: #41184b;
