@import '../../styles/common';

.about {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
  padding: 2rem;
  background: white;
  font-family: OpenSans, sans-serif;
  z-index: 10000000;
  height: fit-content;
}

.closeIcon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2rem;
}