@import '../../styles/common';

.main {
  min-width: 100%;
  min-height: 100%;
  //background-image: linear-gradient(154.37deg, rgba(162, 59, 114, 0.9) 0%, rgba(66, 46, 121, 0.9) 100%), url("../../images/bg-mob.png");
  //background-position: center;
  //background-size: cover;
  //background-attachment: fixed;
  position: relative;

  //@include mq-desktop {
  //  background-image: linear-gradient(154.37deg, rgba(162, 59, 114, 0.9) 0%, rgba(66, 46, 121, 0.9) 100%), url("../../images/bg-desk.png");
  //}
}
.bg {
  background-image: linear-gradient(154.37deg, rgba(162, 59, 114, 0.9) 0%, rgba(66, 46, 121, 0.9) 100%), url("../../images/bg-mob.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;
  @include mq-desktop {
    background-image: linear-gradient(154.37deg, rgba(162, 59, 114, 0.9) 0%, rgba(66, 46, 121, 0.9) 100%), url("../../images/bg-desk.png");
  }
}